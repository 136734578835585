import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export default ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepageBodyHighlight {
    nodes {
      id
      primary {
        message {
          text
        }
      }
    }
  }
    }
  `)
  const highlightData = data.allPrismicHomepageBodyHighlight.nodes.find(cur => cur.id === id).primary
  return (
    <section className='hero is-primary'>
      <div className='hero-body'>
        <div className='container'>
          <div className='columns is-centered'>
            <div className='column is-three-quarters'>
              <h3 className='title is-spaced has-text-centered'>{highlightData.message.text}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
