import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Steps from '../components/steps'
import Highlight from '../components/highlight'
import Links from '../components/links'
import './index.scss'
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = props => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepage {
        nodes {
          lang
          data {
            body {
              ... on PrismicHomepageBodyNavigationBar {
                id
                slice_type
              }
              ... on PrismicHomepageBodyHero {
                id
                slice_type
              }
              ... on PrismicHomepageBodySteps {
                id
                slice_type
              }
              ... on PrismicHomepageBodyHighlight {
                id
                slice_type
              }
              ... on PrismicHomepageBodyLinks {
                id
                slice_type
              }
              ... on PrismicHomepageBodyFooter {
                id
                slice_type
              }
            }
          }
        }
      }
    }
  `)
  const locale = props.pageContext.locale || 'en-us'
  return (
    <Layout locale={locale} pageContext={props.pageContext}
            navBarId={data.allPrismicHomepage.nodes.find(cur => cur.lang === locale).data.body.find(cur => cur.slice_type === 'navigation_bar').id}
            footerId={data.allPrismicHomepage.nodes.find(cur => cur.lang === locale).data.body.find(cur => cur.slice_type === 'footer').id} >
      <SEO/>
      <Hero {...data.allPrismicHomepage.nodes.find(cur => cur.lang === locale).data.body.find(cur => cur.slice_type === 'hero')} />
      <Steps {...data.allPrismicHomepage.nodes.find(cur => cur.lang === locale).data.body.find(cur => cur.slice_type === 'steps')} />
      <Highlight {...data.allPrismicHomepage.nodes.find(cur => cur.lang === locale).data.body.find(cur => cur.slice_type === 'highlight')} />
      <Links {...data.allPrismicHomepage.nodes.find(cur => cur.lang === locale).data.body.find(cur => cur.slice_type === 'links')} />
    </Layout>
  )
}

export default IndexPage
