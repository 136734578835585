import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export default ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepageBodySteps {
        nodes {
          primary {
            subtitle {
              text
            }
            title {
              text
            }
          }
        }
        group(field: id) {
          nodes {
            primary {
              subtitle {
                text
              }
              title {
                text
              }
            }
            items {
              step_description {
                text
              }
              step_title {
                text
              }
              step_image {
                fixed {
                  src
                }
              }
            }
            id
          }
        }
      }
    }
  `)
  const stepsData = data.allPrismicHomepageBodySteps.group.find(cur => cur.nodes.find(cur => cur.id === id)).nodes[0]
  return (
    <section className='section has-top-separator'>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full has-text-centered'>
            <h3 className='title is-3 is-spaced has-text-centered'>{stepsData.primary.title.text}</h3>
            <h4 className='subtitle is-4 has-text-centered'>{stepsData.primary.subtitle.text}</h4>
          </div>
        </div>
        <div className='columns m-t-lg m-b-md'>
          {stepsData.items.map((cur, idx) => (
            <div key={idx} className='column has-text-centered'>
              <img className='step-img' src={cur.step_image.fixed.src} alt='' />
              <h4 className='title is-4 is-spaced'>{cur.step_title.text}</h4>
              <h5 className='subtitle is-5 is-spaced'>{cur.step_description.text}</h5>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
