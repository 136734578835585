import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from "gatsby-image"

export default ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepageBodyLinks {
        group(field: id) {
          nodes {
            id
            primary {
              subtitle {
                text
              }
              title {
                text
              }
            }
            items {
              link {
                url
              }
              link_image {
                alt
                fixed(width: 114) {
                  ...GatsbyPrismicImageFixed
                }
              }
            }
          }
        }
      }
    }
  `)
  const linksData = data.allPrismicHomepageBodyLinks.group.find(cur => cur.nodes.find(cur => cur.id === id)).nodes[0]
  return (
    <section className='section'>
      <div className='container'>
        <div className='columns'>
          <div className='column is-full has-text-centered'>
            <h3 className='title is-3 is-spaced has-text-centered'>{linksData.primary.title.text}</h3>
            <h4 className='subtitle is-4 is-spaced has-text-centered'>{linksData.primary.subtitle.text}</h4>
          </div>
        </div>
      </div>
      <div className='columns m-t-lg m-b-md'>
        {linksData.items.map((cur, idx) => (
          <div key={idx} className='column has-text-centered'>
            <a target='_blank' rel='noopener noreferrer' href={cur.link.url}>
              <Img fixed={cur.link_image.fixed} quality={92} alt={cur.link_image.alt}/>
            </a>
          </div>
        ))}
      </div>
    </section>
  )
}
