import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default ({ id }) => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepageBodyHero {
        nodes {
          primary {
            cta_text {
              text
            }
            image {
              fluid(maxWidth: 1344) {
                ...GatsbyPrismicImageFluid
              }
            }
            subtitle {
              text
            }
            title {
              text
            }
          }
          id
        }
      }
    }
  `)
  const heroData = data.allPrismicHomepageBodyHero.nodes.find(cur => cur.id === id).primary
  return (
    <section className="hero is-medium">
      <div className='hero-body'>
        <div className='container has-text-centered'>
          <h1 className='title is-2 is-spaced'>{heroData.title.text}</h1>
          <h2 className='subtitle is-4'>{heroData.subtitle.text}</h2>
          <a className='button is-medium is-primary is-uppercase' href='websurveys'>{heroData.cta_text.text}</a>
          <section className='m-t-xxl'>
            <Img fluid={heroData.image.fluid} quality={92}/>
          </section>
        </div>
      </div>
    </section>
  )
}
